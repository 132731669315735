<template>
 <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb 
          class="view-user-poaps__breadcrumb"
          :children="breadcrumbs" 
        />
        
        <div class="view-user-poaps__section">
          <div class="view-user-poaps__header">
            <div class="view-user-poaps__texts">
              <h2 class="user__subtitle">{{ $t('user-portal.user_poaps_title') }}</h2>
            </div>

            <actions-button
              class="view-user-poaps__aheader"
              icon-pre="redo"
              :disabled="loading"
              @click="() => onRefresh(true)"
            />
          </div>
        </div>
      </header>

      <div 
        class="user__loader" 
        v-if="loading">
        <ui-loader />
      </div>

      <!-- No NFTS -->
      <data-empty
        class="view-user-poaps__empty" 
        :description="$t('user-portal.user_no_poaps_description')"
        :title="$t('user-portal.user_no_poaps')"
        v-if="!loading && poaps.length === 0"
      />
    
      <div 
        class="view-user-poaps__list"
        v-if="!loading && poaps.length > 0">
        <ui-poap-card
          :key="poap.id"
          :value="poap"
          @click="onPoapClick"
          v-for="poap in poaps"
        />
      </div>

      <div 
        class="view-user-poaps__more"
        v-if="pagination.hasNext && !loading">
        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          class="view-user-poaps__amore"
          :disabled="loadingMore"
          :loading="loadingMore"
          @click="onPage"
        >{{ $t('user-portal.poaps_load_more') }}</actions-button>
      </div>
    </div>

    <router-view v-if="!loading" />
  </main>
</template>

<script>
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'
import UiPoapCard from '@/components/ui/poap-card.vue'

export default {
  name: 'UserPoaps',

  components: {
    NavigationsBreadcrumb,
    UiPoapCard,
  },

  inject: [
    '$poap',
    '$user',
  ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' }},
        { label:  this.$t('user-portal.user_poaps_title'), href: '#' }
      ],
      loading: true,
      loadingMore: true,
    }
  },

  computed: {
    pagination() {
      return this.$poap.pagination
    },

    poaps() {
      return this.$poap.poaps
    },

    user() {
      return this.$user.user
    },
  },

  methods: {
    onPage() {
      this.pagination.page = this.pagination.page + 1
      this.onRefresh()
    },

    onPoapClick(poap) {
      this.$router.push({ name: 'sayl-user-portal.poap', params: { id: poap.id }}).catch(() => {})
    },

    async onRefresh(force = false) {
      try {
        if(force) {
          this.loading = true
          this.pagination.total = 0
          this.pagination.page = 1
          this.pagination.pages = 1
          this.pagination.limit = 20
          this.pagination.hasNext = false
        }

        this.loadingMore = true
        await this.$poap.find({ limit: this.pagination.limit, page: this.pagination.page })
      } catch(e) {
        $console.error(e)
      } finally {
        this.loadingMore = false
        this.loading = false
      }
    },
  },

  mounted() {
    this.onRefresh(true)
  }
}
</script>