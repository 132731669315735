<template>
  <article 
    class="ui-poap-card" 
    @click="$emit('click', value)">
    <ui-asset
      class="ui-poap-card__asset"
      :class="{
        '--no-img': value.image == null
      }"
      :value="asset"
      v-if="asset"
    />

    <div class="ui-poap-card__header">
      <h5 class="ui-poap-card__name">{{ name }}</h5>
      
      <p
        class="ui-poap-card__tx" 
        v-if="txId"
      >{{ txId }}</p>
    </div>
  </article>
</template>

<script>
import UiAsset from './asset.vue'

export default {
  name: 'UiPoapCard',

  inject: [
    '$embed',
  ],

  components: {
    UiAsset,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    value: { 
      type: Object,
      required: true
    }
  },

  computed: {
    asset() {
      let ret = this.$basil.get(this.value, 'image', null)
      if(ret == null) {
        ret = this.$basil.get(this.$embed, 'embed.logo', null)
      }
      return ret
    },

    description() {
      return this.$basil.get(this.value, 'poapLocation.description', null)
    },

    name() {
      return this.$basil.get(this.value, 'poapLocation.name', null)
    },

    txId() {
      return this.$basil.get(this.value, 'transactionId', null)
    }
  },
}
</script>